import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "filterEnabled",
})
export class FilterEnabledPipe implements PipeTransform {
	transform(wallets: any[]): any[] {
		return wallets?.filter((wallet) => wallet.isEnabled) || [];
	}
}
