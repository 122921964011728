<he-base-dialog header="{{dialogTitle}}" [(visible)]="visible" [isLoadingLookups]="isLoadingLookups"
				[style]="{'max-width': '550px', width: '550px'}" (visibleChange)="onCloseDialog()"
				[rejectBtnOnly]="!forCRUD"
				(submitEvent)="onSubmit()" [disableSubmit]="!form.valid">

	<!--<div id="mode-toggle-wrapper" *ngIf="forCreate || (bankDetails && editable && canUpdateBankDetails)">
		<span id="mode-text" [attr.view-mode]="isViewMode">{{isViewMode ? 'edit' : 'view'}}</span>
		<p-inputSwitch [(ngModel)]="isViewMode" (onChange)="onModeToggle($event)"></p-inputSwitch>
	</div>-->

	<form [formGroup]="form" class="dialog-form-placement" autocomplete="off">
		<ng-container *ngIf="transferDetails?.withdrawalMethod">
			<label>Withdrawal Method</label>
			<div style="padding: 0 12px;">{{transferDetails?.withdrawalMethod ?? ''}}</div>
		</ng-container>

		<ng-container *ngIf="transferDetails?.beneficiaryName">
			<label for="accHolderName">Beneficiary Name</label>
			<input pInputText id="accHolderName" formControlName="accHolderName" autocomplete="off" type="text"/>
		</ng-container>

		<ng-container *ngIf="transferDetails?.paymentOrderID">
			<label>Payment Order ID</label>
			<span class="view-only-input">{{transferDetails?.paymentOrderID}}</span>
		</ng-container>

		<ng-container *ngIf="transferDetails?.playerPaymentAccountID">
			<label>Player Payment Account ID</label>
			<span class="view-only-input">{{transferDetails?.playerPaymentAccountID}}</span>
		</ng-container>

		<ng-container *ngIf="transferDetails?.eWalletIdentifier">
			<label>Account Reference</label>
			<span class="view-only-input">{{transferDetails?.eWalletIdentifier}}</span>
		</ng-container>

		<ng-container *ngIf="transferDetails?.mobileNumber">
			<label>Mobile Number</label>
			<span class="view-only-input">{{transferDetails?.mobileNumber}}</span>
		</ng-container>

		<ng-container *ngIf="transferDetails?.nationalIDNumber">
			<label for="nationalIdNum">National ID No.</label>
			<input pInputText id="nationalIdNum" formControlName="nationalIdNum" autocomplete="off" type="text"/>
		</ng-container>

		<ng-container *ngIf="transferDetails?.bankName">
			<label for="bankName">Bank Name</label>
			<input pInputText id="bankName" formControlName="bankName" autocomplete="off" type="text"/>
		</ng-container>

		<ng-container *ngIf="transferDetails?.bankBranch">
			<label for="bankBranch">Bank Branch</label>
			<input pInputText id="bankBranch" formControlName="bankBranch" autocomplete="off" type="text"/>
		</ng-container>

		<ng-container *ngIf="transferDetails?.bankCity">
			<label for="bankCity">City</label>
			<input pInputText id="bankCity" formControlName="bankCity" autocomplete="off" type="text"/>
		</ng-container>

		<ng-container *ngIf="transferDetails?.bankState">
			<label for="bankState">State</label>
			<input pInputText id="bankState" formControlName="bankState" autocomplete="off" type="text"/>
		</ng-container>

		<ng-container *ngIf="transferDetails?.bankBranchCountryID">
			<label>{{'Country'}}</label>
			<p-dropdown [inputId]="'bankBranchCountry'" [options]="unsanctionedCountries" appendTo="body"
						formControlName="bankBranchCountry" placeholder="Please select a country">
			</p-dropdown>
		</ng-container>

		<ng-container *ngIf="transferDetails?.bankAccountNumber">
			<label for="bankAccNumber">Account Number</label>
			<input pInputText id="bankAccNumber" formControlName="bankAccNumber" autocomplete="off" type="text"/>
		</ng-container>

		<ng-container *ngIf="transferDetails?.iban">
			<label for="iban">IBAN</label>
			<input pInputText id="iban" formControlName="iban" autocomplete="off" type="text"/>
		</ng-container>

		<ng-container *ngIf="transferDetails?.swiftCode">
			<label for="swiftCode">SWIFT Code</label>
			<input pInputText id="swiftCode" formControlName="swiftCode" autocomplete="off" type="text"/>
		</ng-container>

		<ng-container *ngIf="transferDetails?.routingNumber">
			<label for="routingNum">Routing No.</label>
			<input pInputText id="routingNum" formControlName="routingNum" autocomplete="off" type="text"/>
		</ng-container>

		<ng-container *ngIf="transferDetails?.ifsc">
			<label for="ifsc">IFSC</label>
			<input pInputText id="ifsc" formControlName="ifsc" autocomplete="off" type="text"/>
		</ng-container>
	</form>
</he-base-dialog>
